import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import cn from "../styles/books.module.scss"

const BooksPage = ({ data }) => {
  const books = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Books" />
      <div className={cn.content}>
        {books &&
          books.map(({ node: book }) => {
            const { slug } = book.fields
            const { id } = book
            return (
              <Link key={id} to={slug} className={cn.bookWrapper}>
                <Img
                  fluid={book.frontmatter.coverimage.childImageSharp.fluid}
                  alt="O menino com asas nos pés"
                  className={cn.book}
                />
              </Link>
            )
          })}
      </div>
    </Layout>
  )
}

BooksPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BooksPage

export const pageQuery = graphql`
  query BooksPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "single-book" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            coverimage {
              childImageSharp {
                fluid(maxWidth: 325) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
